import React, { useEffect, useState } from "react";
import {
  SIDE_NAV_WIDTH,
  SIDE_NAV_COLLAPSED_WIDTH,
  NAV_TYPE_TOP,
} from "constants/ThemeConstant";
import { APP_NAME } from "configs/AppConfig";
import { connect } from "react-redux";
import utils from "utils";
import { Grid } from "antd";
import { Avatar, Badge } from "antd";
import { UserOutlined } from "@ant-design/icons";
import UserService from "base/service/services/UserService";

const { useBreakpoint } = Grid;

const getLogoWidthGutter = (props, isMobile) => {
  const { navCollapsed, navType } = props;
  const isNavTop = navType === NAV_TYPE_TOP ? true : false;
  if (isMobile && !props.mobileLogo) {
    return 0;
  }
  if (isNavTop) {
    return "auto";
  }
  if (navCollapsed) {
    return `${SIDE_NAV_COLLAPSED_WIDTH}px`;
  } else {
    return `${SIDE_NAV_WIDTH}px`;
  }
};



export const UserDropdown = (props) => {
  const _UserService = new UserService();
  const [user, setUser] = useState(null);
  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes("lg");
  useEffect(() => {
    _UserService.getUser().then((user) => {
      setUser(user?.user);
    })
    
  }, [props]);
  return (
    <div
    className="avatar-item"
      style={{ width: `${getLogoWidthGutter(props, isMobile)}` }}
    >
        {props.navCollapsed ? 
          <Avatar shape="square" icon={<UserOutlined />} src={user?.image} />
        :
        <>
          <Avatar shape="square" icon={<UserOutlined />} src={user?.image} />
          
      <div className="user-informations">
            <h2>{user?.firstName + " " + user?.lastName}</h2>
            <span>Süper Yönetici</span>
          </div>
          </>
  }
    </div>
  );
};

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, navType } = theme;
  return { navCollapsed, navType };
};

export default connect(mapStateToProps)(UserDropdown);
