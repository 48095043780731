import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH } from 'configs/AppConfig'

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route path={`${APP_PREFIX_PATH}/home`} component={lazy(() => import(`./home`))} />
        <Route path={`${APP_PREFIX_PATH}/order`} component={lazy(() => import(`./order`))} />
        <Route path={`${APP_PREFIX_PATH}/reservation`} component={lazy(() => import(`./reservation`))} />
        <Route path={`${APP_PREFIX_PATH}/product`} component={lazy(() => import(`./product`))} />
        <Route path={`${APP_PREFIX_PATH}/tour`} component={lazy(() => import(`./tour`))} />
        <Route path={`${APP_PREFIX_PATH}/rent-a-car`} component={lazy(() => import(`./rent-a-car`))} />
        <Route path={`${APP_PREFIX_PATH}/transfer`} component={lazy(() => import(`./transfer`))} />
        <Route path={`${APP_PREFIX_PATH}/hotel`} component={lazy(() => import(`./hotel`))} />
        <Route path={`${APP_PREFIX_PATH}/room`} component={lazy(() => import(`./room`))} />
        <Route path={`${APP_PREFIX_PATH}/product-category`} component={lazy(() => import(`./product-category`))} />
        <Route path={`${APP_PREFIX_PATH}/tour-category`} component={lazy(() => import(`./tour-category`))} />
        <Route path={`${APP_PREFIX_PATH}/tour-region`} component={lazy(() => import(`./tour-region`))} />
        <Route path={`${APP_PREFIX_PATH}/blog`} component={lazy(() => import(`./blog`))} />
        <Route path={`${APP_PREFIX_PATH}/campaigns/discount`} component={lazy(() => import(`./discount`))} />
        <Route path={`${APP_PREFIX_PATH}/page`} component={lazy(() => import(`./page`))} />
        <Route path={`${APP_PREFIX_PATH}/blog-category`} component={lazy(() => import(`./blog-category`))} />
        <Route path={`${APP_PREFIX_PATH}/user`} component={lazy(() => import(`./user`))} />
        <Route path={`${APP_PREFIX_PATH}/food`} component={lazy(() => import(`./food`))} />
        <Route path={`${APP_PREFIX_PATH}/appearance/menu`} component={lazy(() => import(`./menu`))} />
        <Route path={`${APP_PREFIX_PATH}/marketing/email`} component={lazy(() => import(`./email`))} />
        <Route path={`${APP_PREFIX_PATH}/exercise`} component={lazy(() => import(`./exercise`))} />
        <Route path={`${APP_PREFIX_PATH}/settings`} component={lazy(() => import(`./settings`))} />
        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/home`} />
      </Switch>
    </Suspense>
  )
}

export default React.memo(AppViews);