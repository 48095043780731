import {
	HomeOutlined,
	StockOutlined,
	TeamOutlined,
	CarOutlined,
	FileTextOutlined,
	UnorderedListOutlined,
	PercentageOutlined,
	HourglassOutlined,
	NodeIndexOutlined,
	SettingOutlined
} from '@ant-design/icons';
import { APP_PREFIX_PATH } from 'configs/AppConfig'

const dashBoardNavTree = [

			{
				key: 'home',
				path: `${APP_PREFIX_PATH}/home`,
				title: 'home',
				icon: HomeOutlined,
				breadcrumb: false,
				selected: true,
				submenu: []
			}, {
				key: 'order',
				path: `${APP_PREFIX_PATH}/order/list`,
				title: 'orders',
				icon: StockOutlined,
				breadcrumb: false,
				submenu: []
			},
			{
				key: 'reservations',
				path: `${APP_PREFIX_PATH}/reservation/list`,
				title: 'reservations',
				icon: HourglassOutlined,
				breadcrumb: false,
				submenu: []
			},
			// {
			// 	key: 'product',
			// 	path: `${APP_PREFIX_PATH}/product`,
			// 	title: 'products',
			// 	icon: PicLeftOutlined,
			// 	breadcrumb: false,
			// 	submenu: [
			// 		{
			// 			key: 'product-list',
			// 			path: `${APP_PREFIX_PATH}/product/list`,
			// 			title: 'product.list',
			// 			icon: '',
			// 			breadcrumb: false,
			// 			submenu: []
			// 		},
			// 		{
			// 			key: 'product-add',
			// 			path: `${APP_PREFIX_PATH}/product/add`,
			// 			title: 'product.add',
			// 			icon: '',
			// 			breadcrumb: false,
			// 			submenu: []
			// 		},
			// 		{
			// 			key: 'product-category',
			// 			path: `${APP_PREFIX_PATH}/product-category`,
			// 			title: 'product.categories',
			// 			icon: '',
			// 			breadcrumb: false,
			// 			submenu: []
			// 		}
			// 	]
			// },
			{
				key: 'tour',
				path: `${APP_PREFIX_PATH}/tour`,
				title: 'tours',
				icon: NodeIndexOutlined,
				breadcrumb: false,
				submenu: [
					{
						key: 'tour-list',
						path: `${APP_PREFIX_PATH}/tour/list`,
						title: 'tour.list',
						icon: '',
						breadcrumb: false,
						submenu: []
					},
					{
						key: 'tour-add',
						path: `${APP_PREFIX_PATH}/tour/add`,
						title: 'tour.add',
						icon: '',
						breadcrumb: false,
						submenu: []
					},
					{
						key: 'tour-category',
						path: `${APP_PREFIX_PATH}/tour-category`,
						title: 'tour.categories',
						icon: '',
						breadcrumb: false,
						submenu: []
					},
					{
						key: 'tour-region',
						path: `${APP_PREFIX_PATH}/tour-region`,
						title: 'tour.regions',
						icon: '',
						breadcrumb: false,
						submenu: []
					}
				]
			},
			{
				key: 'rent-a-car',
				path: `${APP_PREFIX_PATH}/rent-a-car`,
				title: 'rent-a-cars',
				icon: NodeIndexOutlined,
				breadcrumb: false,
				submenu: [
					{
						key: 'rent-a-car-list',
						path: `${APP_PREFIX_PATH}/rent-a-car/list`,
						title: 'rent-a-car.list',
						icon: '',
						breadcrumb: false,
						submenu: []
					},
					{
						key: 'rent-a-car-add',
						path: `${APP_PREFIX_PATH}/rent-a-car/add`,
						title: 'rent-a-car.add',
						icon: '',
						breadcrumb: false,
						submenu: []
					}
				]
			},
			{
				key: 'transfer',
				path: `${APP_PREFIX_PATH}/transfer`,
				title: 'transfers',
				icon: CarOutlined,
				breadcrumb: false,
				submenu: [
					{
						key: 'transfer-list',
						path: `${APP_PREFIX_PATH}/transfer/list`,
						title: 'transfer.list',
						icon: '',
						breadcrumb: false,
						submenu: []
					},
					{
						key: 'transfer-add',
						path: `${APP_PREFIX_PATH}/transfer/add`,
						title: 'transfer.add',
						icon: '',
						breadcrumb: false,
						submenu: []
					}
				]
			},

			{
				key: 'hotel',
				path: `${APP_PREFIX_PATH}/hotel`,
				title: 'hotels',
				icon: HomeOutlined,
				breadcrumb: false,
				submenu: [
					{
						key: 'hotel-list',
						path: `${APP_PREFIX_PATH}/hotel/list`,
						title: 'hotel.list',
						icon: '',
						breadcrumb: false,
						submenu: []
					},
					{
						key: 'hotel-add',
						path: `${APP_PREFIX_PATH}/hotel/add`,
						title: 'hotel.add',
						icon: '',
						breadcrumb: false,
						submenu: []
					},
				]
			},
			{
				key: 'users',
				path: `${APP_PREFIX_PATH}/user/list`,
				title: 'users',
				icon: TeamOutlined,
				breadcrumb: false,
				submenu: []
			},
			{
				key: 'blog',
				path: `${APP_PREFIX_PATH}/blog`,
				title: 'blogs',
				icon: FileTextOutlined,
				breadcrumb: false,
				submenu: [
					{
						key: 'blog-list',
						path: `${APP_PREFIX_PATH}/blog/list`,
						title: 'blog.list',
						icon: '',
						breadcrumb: false,
						submenu: []
					},
					{
						key: 'blog-add',
						path: `${APP_PREFIX_PATH}/blog/add`,
						title: 'blog.add',
						icon: '',
						breadcrumb: false,
						submenu: []
					},
					{
						key: 'blog-category',
						path: `${APP_PREFIX_PATH}/blog-category`,
						title: 'blog.categories',
						icon: '',
						breadcrumb: false,
						submenu: []
					}
				]
			},
			{
				key: 'page',
				path: `${APP_PREFIX_PATH}/page`,
				title: 'pages',
				icon: UnorderedListOutlined,
				breadcrumb: false,
				submenu: [
					{
						key: 'page-list',
						path: `${APP_PREFIX_PATH}/page/list`,
						title: 'page.list',
						icon: '',
						breadcrumb: false,
						submenu: []
					},
					{
						key: 'page-add',
						path: `${APP_PREFIX_PATH}/page/add`,
						title: 'page.add',
						icon: '',
						breadcrumb: false,
						submenu: []
					}
				]
			},
			
			{
				key: 'campaigns',
				path: `${APP_PREFIX_PATH}/campaign/list`,
				title: 'campaigns',
				icon: PercentageOutlined,
				breadcrumb: false,
				submenu: [
					{
						key: 'discounts',
						path: `${APP_PREFIX_PATH}/campaigns/discount/list`,
						title: 'discounts',
						breadcrumb: false,
						submenu: []
					},
				]
			},
			{
				key: 'appearance',
				path: `${APP_PREFIX_PATH}/appearance`,
				title: 'appearance',
				icon: FileTextOutlined,
				breadcrumb: false,
				submenu: [
					{
						key: 'menu',
						path: `${APP_PREFIX_PATH}/appearance/menu`,
						title: 'appearance.menu',
						icon: '',
						breadcrumb: false,
						submenu: []
					}
				]
			}, 
	
	{
		key: 'settings',
		path: `${APP_PREFIX_PATH}/settings`,
		title: 'settings',
		icon: SettingOutlined,
		breadcrumb: false,
		submenu: []
	},
	// {
	//   key: 'foods',
	//   path: `${APP_PREFIX_PATH}/food/list`,
	//   title: 'foods',
	//   icon: PieChartOutlined,
	//   breadcrumb: false,
	//   submenu: []
	// },{
	//   key: 'exercises',
	//   path: `${APP_PREFIX_PATH}/exercise/list`,
	//   title: 'exercises',
	//   icon: ThunderboltOutlined,
	//   breadcrumb: false,
	//   submenu: []
	// }
]

const navigationConfig = [
	...dashBoardNavTree
]

export default navigationConfig;
