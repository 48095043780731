import { NAV_TYPE_SIDE, NAV_TYPE_TOP, SIDE_NAV_LIGHT } from 'constants/ThemeConstant';
import { env } from './EnvironmentConfig'

export const APP_NAME = 'Alanya Tekmar';
export const API_BASE_URL = env.API_ENDPOINT_URL
export const APP_PREFIX_PATH = '/app';
export const AUTH_PREFIX_PATH = '/auth';

export const THEME_CONFIG = {
	navCollapsed: false,
	sideNavTheme: SIDE_NAV_LIGHT,
	locale: 'tr',
	navType: NAV_TYPE_TOP,
	topNavColor: '#0093d6',
	headerNavColor: '#fff',
	mobileNav: false,
	currentTheme: 'light'
};
