import axios from "axios";
import crypto from "crypto-js";
import { APIResult } from "../dto/ApiResult.js";
import { BaseConfig } from "../dto/BaseConfig";
import {
  AUTH_TOKEN
} from 'redux/constants/Auth';
export class BaseService {
  constructor(serviceName) {
    this.serviceName = serviceName;
  }

  //#region REST
  async get(url) {
    try {
      const requestOptions = {
        headers: {},
      };
      const userData = await this.getUser();
      if (userData) {
        requestOptions.headers = {
          Authorization: 'Bearer ' + userData.access_token,

        }
      }
      const response = await axios.get(
        `${BaseConfig.API_URL + this.serviceName + url}`,
        requestOptions,
      );

      return await this.handleResponse(response);
    } catch (error) {
      let apiResult = new APIResult();
      apiResult.isSuccess = false;
      if (error) {
        apiResult.message = error;
      }
      return apiResult;
    }
  }
  async getToken() {
    const userData = await this.getUser();
    return userData.access_token;
  }
  async post(url, body) {
    try {
      const requestOptions = {
        headers: {},
      };

      const userData = await this.getUser();

      if (userData) {
        requestOptions.headers['Authorization'] =
          'Bearer ' + userData.access_token;
      }

      const responseData = await axios.post(
        `${BaseConfig.API_URL + this.serviceName + url}`,
        body,
        requestOptions,
      )
      const decryptedData = await this.handleResponse(responseData)
      return decryptedData

    } catch (error) {
      let apiResult = new APIResult();
      apiResult.isSuccess = false;
      apiResult.data = error.response?.data?.data;
      if (error) {
        apiResult.message = error.response.data.message;
      }
      return apiResult;
    }

  }


  async put(url, body) {
    try {
      const requestOptions = {
        headers: {},
      };
      const userData = await this.getUser();
      if (userData) {
        requestOptions.headers["Authorization"] =
          "Bearer " + userData.access_token;
      }

      const response = await axios
        .put(
          `${BaseConfig.API_URL + this.serviceName + url}`,
          body,
          requestOptions
        )
        .catch((error) => {
          throw error.response.data;
        });
      return await this.handleResponse(response);
    } catch (error) {
      throw error;
    }
  }

  async upload(url, formData, callback) {
    try {
      let config = {
        onUploadProgress: function (progressEvent) {
          var percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total)
          callback(percentCompleted);
        },
        // "content-type": "multipart/form-data"

      }
      const userData = await this.getUser();
      if (userData) {
        config.headers["Authorization"] =
          "Bearer " + userData.access_token;
      }

      const response = await axios.post(
        `${BaseConfig.API_URL + this.serviceName + url}`, formData,
        config
      );
      return await this.handleResponse(response);
    } catch (error) {
      let apiResult = new APIResult();
      apiResult.isSuccess = false;
      apiResult.message = error;
    }
  }

  async delete(url) {
    try {
      const requestOptions = {
        headers: {},
      };
      const userData = await this.getUser();
      if (userData) {
        requestOptions.headers = {
          Authorization: "Bearer " + userData.access_token,
        };
      }
      const response = await axios
        .delete(
          `${BaseConfig.API_URL + this.serviceName + url}`,
          requestOptions
        )
        .catch((error) => {
          throw error.response.data;
        });
      return await this.handleResponse(response);
    } catch (error) {
      throw error;
    }
  }

  async upload(url, formData) {
    try {
      const requestOptions = {
        headers: {},
      };
      const userData = await this.getUser();
      if (userData) {
        requestOptions.headers["Authorization"] =
          "Bearer " + userData.access_token;
      }

      const response = await axios
        .post(
          `${BaseConfig.API_URL + this.serviceName + url}`,
          formData,
          requestOptions
        )
        .catch((error) => {
          throw error.response.data;
        });
      return await this.handleResponse(response);
    } catch (error) {
      throw error;
    }
  }

  //#region CRYPTO

  async handleResponse(response) {
    let apiResult = new APIResult();

    try {
      if (response.status != 200) {
        apiResult.isSuccess = false;
        if (response.statusText) {
          apiResult.message = response.message;
        }
        return apiResult;
      }
      if (response.data && response.data.isSuccess) {
        apiResult.data = response.data.data;
      }
      apiResult.isSuccess = true;
    } catch (error) {
      apiResult.isSuccess = false;
      apiResult.message = response.message;
    }
    return apiResult;
  }
  //#endregion

  //#region STORAGE

  async setStorage(storageName, storageData) {
    try {
      await localStorage.setItem(storageName, JSON.stringify(storageData));
      return true;
    } catch (error) {
      return false;
    }
  }

  async clearStorage() {
    try {
      await localStorage.clear();
    } catch (error) {
    }
  }

  async removeStorage(storageName) {
    try {
      await localStorage.removeItem(storageName);
    } catch (error) {
    }
  }

  async getStorage(storageName) {
    try {
      const data = await localStorage.getItem(storageName);
      let storageData = null;
      if (data) {
        storageData = JSON.parse(data);
      }
      return storageData;
    } catch (error) {
      return null;
    }
  }

  //#endregion

  //#region USER
  async getUser() {
    try {
      const userData = await this.getStorage(AUTH_TOKEN);
      if (userData) {
        let createDate = new Date(userData.createDate);
        createDate.setSeconds(createDate.getSeconds() + userData.expires_in);
        if (createDate < new Date()) {
          await this.removeStorage(AUTH_TOKEN);
          return null;
        }
        return userData;
      }
      return userData;
    } catch (error) {
      return null;
    }
  }
  //#endregion
}
