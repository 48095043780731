import enLang from './entries/en_US';
import ruLang from './entries/ru_RU';
import TrLang from './entries/tr_TR';

const AppLocale = {
    en: enLang,
    ru: ruLang,
    tr: TrLang
};

export default AppLocale;