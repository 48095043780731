import React, { Component } from "react";
import { SettingOutlined, LogoutOutlined } from "@ant-design/icons";
import { Drawer, Menu, Popconfirm, message } from "antd";
import ThemeConfigurator from "./ThemeConfigurator";
import { connect } from "react-redux";
import NavLanguage from "./NavLanguage";
import UserService from "base/service/services/UserService";

const text = 'Çıkış yapmak istediğinize emin misiniz?';

const confirm = () => {
  _UserService.clearStorage();

  message.info("Başarıyla çıkış yapıldı, lütfen bekleyiniz.");
  setTimeout(() => {

    window.location.reload();
  }, 500);

};

const ListOption = ({ name, selector, disabled, vertical }) => (
  <div
    className={`mr-4 ${vertical ? "" : "d-flex align-items-center justify-content-between"
      }`}
  >
    <div
      className={`${disabled ? "opacity-0-3" : ""} ${vertical ? "mb-3" : ""}`}
    >
      {name}
    </div>
    <div>{selector}</div>
  </div>
);
const _UserService = new UserService();
export class NavPanel extends Component {

  state = { visible: false };

  showDrawer = () => {
    this.setState({
      visible: true,
    });
  };

  onClose = () => {
    this.setState({
      visible: false,
    });
  };

  logOut = () => {
  }
  render() {
    return (
      <>
        <Menu mode="horizontal">
          <NavLanguage configDisplay />
          <Menu.Item onClick={this.showDrawer}>
            <SettingOutlined className="nav-icon mr-0" />
          </Menu.Item>
          <Menu.Item onClick={this.logOut}>
            <Popconfirm
              placement="bottomLeft"
              title={text}
              onConfirm={confirm}
              okText="Evet"
              cancelText="Hayır"
            >
              <LogoutOutlined />
            </Popconfirm>
          </Menu.Item>
        </Menu>
        <Drawer
          title="Theme Config"
          placement="right"
          width={350}
          onClose={this.onClose}
          visible={this.state.visible}
        >
          <ThemeConfigurator />
        </Drawer>
      </>
    );
  }
}

const mapStateToProps = ({ theme }) => {
  const { locale } = theme;
  return { locale };
};

export default connect(mapStateToProps)(NavPanel);
