import React from "react";
import { Layout } from 'antd';
import { connect } from 'react-redux';
import { SIDE_NAV_WIDTH, SIDE_NAV_DARK, NAV_TYPE_SIDE } from 'constants/ThemeConstant';
import { Scrollbars } from 'react-custom-scrollbars';
import MenuContent from './MenuContent'
import Logo from './Logo';
import UserDropdown from './UserDropdown';
import utils from 'utils'

const { Sider } = Layout;

export const SideNav = ({navCollapsed, sideNavTheme, routeInfo, hideGroupTitle, localization = true, headerNavColor,currentTheme }) => {
  const props = { sideNavTheme, routeInfo , hideGroupTitle, localization, headerNavColor,currentTheme}
  
const mode = ()=> {
  if(!headerNavColor) {
    return utils.getColorContrast(currentTheme === 'dark' ? '#00000' : '#ffffff' )
  }
  return utils.getColorContrast(headerNavColor)
}
const navMode = mode()
  return (
    <Sider 
      className={`side-nav ${sideNavTheme === SIDE_NAV_DARK? 'side-nav-dark' : ''}`} 
      width={SIDE_NAV_WIDTH} 
      collapsed={navCollapsed}
    >
      <Scrollbars autoHide>
      <Logo logoType={navMode}/>
      <UserDropdown logoType={navMode}/>
        <MenuContent 
          type={NAV_TYPE_SIDE} 
          {...props}
        />
        <div className="d-flex align-items-center justify-content-center" style={{position:"fixed;", bottom:0, width:"250px", background:"#fff", padding:"10px 0"}}>
          <a href="https://lime.com.tr" target="_blank">
          <img height="70xpx" src="../../lime.svg" alt="" /></a>
        </div>
      </Scrollbars>
    </Sider>
  )
}

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, sideNavTheme, headerNavColor,currentTheme } =  theme;
  return { navCollapsed, sideNavTheme, headerNavColor,currentTheme }
};

export default connect(mapStateToProps)(SideNav);
